<template>
  <v-container>
    <v-overlay :value="overlay">
      <v-progress-circular
        indeterminate
        size="64"
      />
    </v-overlay>
    <v-stepper
      id="workspace-stepper"
      v-model="e6"
    >
      <v-stepper-header>
        <v-stepper-step
          v-if="routeType == 'URL'"
          :complete="e6 > 1"
          step="1"
          dark
          color="color_green"
        >
          Hierarchical Url Structure
        </v-stepper-step>
        <v-stepper-step
          v-else-if="routeType == 'REPORT-HUB'"
          :complete="e6 > 1"
          step="1"
          dark
          color="color_green"
        >
          Hierarchical Folder Structure
        </v-stepper-step>
        <v-stepper-step
          v-else
          :complete="e6 > 1"
          step="1"
          dark
          color="color_green"
        >
          Hierarchical Documental Structure
        </v-stepper-step>
        <v-divider />
      </v-stepper-header>
      <v-form>
        <v-stepper-items>
          <v-stepper-content step="1">
            <service-treeview
              :route-type="routeType"
              :is-creating-profiling="true"
              @update-crm-folder="crm_folder = $event"
              @update-crm-id="crm_id = $event"
              @update-starting-year="starting_year = Number($event)"
            />
            <v-row class="mt-6">
              <v-col cols="1">
                <v-btn
                  dark
                  color="warning"
                  @click="$router.go(-1)"
                >
                  Close
                </v-btn>
              </v-col>
              <v-spacer />
              <v-btn
                class="mt-3"
                text
                @click="e6 = 1"
              >
                Cancel
              </v-btn>
              <v-col cols="2">
                <v-btn
                  dark
                  color="color_green"
                  @click="confirmConfiguration()"
                >
                  Confirm
                </v-btn>
              </v-col>
            </v-row>
          </v-stepper-content>
        </v-stepper-items>
      </v-form>
    </v-stepper>
  </v-container>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { findItemNested, modifiedHierarchyItemsOnly, setOldNameToEditedItems, deletedHierarchyItemsOnly, createdHierarchyItemsOnly } from '@/utils.js';

export default {
  components: {
    ServiceTreeview: () => import('@/components/services/ServiceTreeview')
  },
  data() {
    return {
      search: '',
      loading: false,
      overlay: false,
      e6: 1,
      routeType: '',
      fileInput: null,
      editedConfigurationItemsOnly: [],
      hierarchyItemsEdited: [],
      hierarchyItemsCreated: [],
      hierarchyItemsDeleted: [],
      alert: false,
      crm_folder: false,
      crm_id: null,
      starting_year: null
    };
  },
  computed: {
    ...mapState(['services'])
  },
  watch: {
    $route() {}
  },
  created() {
    this.routeType = this.$route.params.type;
  },
  methods: {
    ...mapActions([
      'createUrlServiceAction',
      'editUrlServiceAction',
      'deleteUrlServiceAction',
      'createDocumentalServiceAction',
      'editDocumentalServiceAction',
      'deleteDocumentalServiceAction',
      'createReportHubServiceAction',
      'editReportHubServiceAction',
      'deleteReportHubServiceAction',
      'storeServices'
    ]),
    saveHierarchyItemsLinear() {
      this.services.hierarchyItemsLinear = [];
      this.hierarchyItemsEdited = [];
      this.hierarchyItemsCreated = [];
      this.hierarchyItemsDeleted = [];

      if (this.services.existingServicesConfiguration.length > 0) {
        findItemNested(
          this.services.hierarchyItems,
          'children',
          this.services.hierarchyItemsLinear,
          this.routeType
        );

        this.hierarchyItemsDeleted = deletedHierarchyItemsOnly(this.services.hierarchyItemsLinear, this.services.existingServicesConfiguration);

        const editedConfigurationItemsOnly = modifiedHierarchyItemsOnly(this.services.hierarchyItemsLinear, this.services.existingServicesConfiguration);

        setOldNameToEditedItems(editedConfigurationItemsOnly, this.services.existingServicesConfiguration);

        createdHierarchyItemsOnly(editedConfigurationItemsOnly, this.hierarchyItemsEdited, this.hierarchyItemsCreated);

      } else {
        findItemNested(
          this.services.hierarchyItems,
          'children',
          this.services.hierarchyItemsLinear,
          this.routeType
        );
      }
    },
    async confirmConfiguration() {
      this.saveHierarchyItemsLinear();

      if (this.routeType == 'URL') {
        if (this.services.existingServicesConfiguration.length > 0) {
          if (this.hierarchyItemsEdited.length > 0) {
            this.overlay = true;
            await this.editUrlServiceAction(this.hierarchyItemsEdited);
            this.overlay = false;

            if (this.$route.path != '/services') {
              this.$router.push('/services');
            }
          }

          if (this.hierarchyItemsCreated.length > 0) {
            this.overlay = true;
            await this.createUrlServiceAction(this.hierarchyItemsCreated);
            this.overlay = false;

            if (this.$route.path != '/services') {
              this.$router.push('/services');
            }
          }

          if (this.hierarchyItemsDeleted.length > 0) {
            this.overlay = true;
            await this.deleteUrlServiceAction(this.hierarchyItemsDeleted);
            this.overlay = false;

            if (this.$route.path != '/services') {
              this.$router.push('/services');
            }
          }
        } else {
          if (this.services.hierarchyItemsLinear.length > 0) {
            this.overlay = true;
            await this.createUrlServiceAction(
              this.services.hierarchyItemsLinear
            );
            this.overlay = false;
          }

          this.$router.go(-1);
        }
      }

      if (this.routeType == 'DOC') {
        if (this.services.existingServicesConfiguration.length > 0) {
          if (this.hierarchyItemsEdited.length > 0) {
            this.overlay = true;
            await this.editDocumentalServiceAction(this.hierarchyItemsEdited);
            this.overlay = false;

            if (this.$route.path != '/services') {
              this.$router.push('/services');
            }
          }

          if (this.hierarchyItemsCreated.length > 0) {
            this.overlay = true;
            await this.createDocumentalServiceAction(
              this.hierarchyItemsCreated
            );
            this.overlay = false;

            if (this.$route.path != '/services') {
              this.$router.push('/services');
            }
          }

          if (this.hierarchyItemsDeleted.length > 0) {
            this.overlay = true;
            await this.deleteDocumentalServiceAction(
              this.hierarchyItemsDeleted
            );
            this.overlay = false;
            await this.storeServices();

            if (this.$route.path != '/services') {
              this.$router.push('/services');
            }
          }
        } else {
          if (this.services.hierarchyItemsLinear.length > 0) {
            this.overlay = true;
            await this.createDocumentalServiceAction(
              this.services.hierarchyItemsLinear
            );
            this.overlay = false;
          }

          this.$router.go(-1);
        }
      }

      if (this.routeType == 'REPORT-HUB') {
        const mappingObj = { crm_id: this.crm_id?.crm_id ?? null, starting_year: this.starting_year || null };

        if (this.services.existingServicesConfiguration.length > 0) {
          await this.editReportHubServiceAction(
            [{ ...mappingObj, service_id: this.services.existingServicesConfiguration[0].service_id }]
          );

          if (this.hierarchyItemsEdited.length > 0) {
            this.overlay = true;
            await this.editReportHubServiceAction(
              this.hierarchyItemsEdited.map(e => ({ ...e, ...mappingObj }))
            );
            this.overlay = false;

            if (this.$route.path != '/services') {
              this.$router.push('/services');
            }
          }

          if (this.hierarchyItemsCreated.length > 0) {
            this.overlay = true;
            await this.createReportHubServiceAction(
              this.hierarchyItemsCreated.map(e => ({ ...e, ...mappingObj }))
            );
            this.overlay = false;

            if (this.$route.path != '/services') {
              this.$router.push('/services');
            }
          }

          if (this.hierarchyItemsDeleted.length > 0) {
            this.overlay = true;
            await this.deleteReportHubServiceAction(
              this.hierarchyItemsDeleted
            );
            this.overlay = false;
            await this.storeServices();

            if (this.$route.path != '/services') {
              this.$router.push('/services');
            }
          }

          if (this.$route.path != '/services') {
            this.$router.push('/services');
          }
        } else {
          if (this.services.hierarchyItemsLinear.length > 0) {
            this.overlay = true;
            await this.createReportHubServiceAction(
              this.services.hierarchyItemsLinear.map(e => ({ ...e, ...mappingObj }))
            );
            this.overlay = false;
          }

          this.$router.go(-1);
        }
      }
    }
  }
};
</script>

<style>
.v-sheet--offset {
  top: -24px;
  position: relative;
}
</style>

<style scoped>
.file-input-width {
  width: 600px;
}
</style>
